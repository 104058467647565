import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LanguageBar from '../../components/lang_menu';
import { Grid, Box } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  body: {
    backgroundImage: "url('/img/login_bg.png')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '100%',
    height: '100vh',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: "url('/img/login_bg_mbl.png')",
      backgroundPosition: 'center 25%',
    },
  },
  main: {
    height: '90vh',
  },
  paper: {
    backgroundColor: 'rgba(235, 235, 235, 0.9)',
    borderRadius: 12,
    maxWidth: '95vw',
    textAlign: 'center',
    marginBottom: '35%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: 550,
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  headingBar: {
    [theme.breakpoints.up('sm')]: {
      padding: '60px 30px 0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '25px 10px 0 10px',
    },
  },
}));

const Landing = (props) => {
  const classes = styles();
  const { MainContent } = props;

  return (
    <div className={classes.body}>
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.headingBar}>
        <Grid item>
          <img src={'/img/hl_logo_name.png'} style={{ height: 40 }} alt="Logo" />
        </Grid>
        <Grid item>
          <LanguageBar />
        </Grid>
      </Grid>
      <main>
        <Grid container direction="row" justify="center" alignItems="flex-end" className={classes.main}>
          <Grid item>
            <Box className={classes.paper} style={{ color: '#717171' }}>
              {MainContent || null}
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <a href="https://beian.miit.gov.cn/ " target="_blank" rel="noopener noreferrer">
            沪ICP备16033578号-3
          </a>
        </Grid>
      </main>
    </div>
  );
};

Landing.propTypes = {
  MainContent: PropTypes.element,
};

export default Landing;
